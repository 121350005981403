import {HTTP} from '../http-services';
import _ from 'lodash';
// initial state
const state = {
    user : {
        validUser : false,
        firstName : 'no first name',
        lastName :  'no last name',
        fullName : 'no full name',
        emailAddress:  '',
        acls :[],
        username : 'nouser',
    },

    isLoggedIn : false,
    showBeta : false,
    redirectGuard : false,
    AclArray: []
};

// getters
// acl array
// acl scope
// acl authorization
//‘ h1-h2-h3-h4[scope1|scope2|scope3|]:authorization

function getScopesForAcl(state,aclParam) {
    let aclIndex = _.findIndex(state.AclArray,{'acl' : aclParam});
    if (aclIndex === -1) {
        return [];
    } else {
        return state.AclArray[aclIndex].scope;
    }
}

function hasAuthForAcl(state,aclParam,authorizationLetter) {
    let aclIndex = _.findIndex(state.AclArray,{'acl' : aclParam});
    if (aclIndex === -1) {
        return false;
    } else {
        let authStr = state.AclArray[aclIndex].authorization;
        return (authStr.indexOf(authorizationLetter) > -1);
    }

}

const getters = {
    getUser :(state) => {
        return state.user;
    },
    getACLS:(state) => {
        return state.AclArray;
    },
    filterScopesByAcls: (state) => (aclParam,scopeList) => {
        let validScopes = getScopesForAcl(state,aclParam);
        if (validScopes.length === 0) {
            return [];
        } else {
            if (_.indexOf(validScopes,"*") >= 0) {
                return scopeList;
            }
            return _.filter(scopeList, (o) => {
                let i = _.indexOf(validScopes,o);
                if (i >= 0) {
                    return true;
                } else {
                    return false;
                }
            });
        }
    },
    getAllowedScopesForAcl: (state) => (aclParam) => {
        return getScopesForAcl(state,aclParam);
    },
    hasAcl: (state) => (aclToCheck) => {
        return (_.findIndex(state.AclArray,{'acl' : aclToCheck}) !== -1);
    },
    hasAuthorizationForAcl: (state) => (aclParam,authorizationLetter) => {
        return hasAuthForAcl(state,aclParam,authorizationLetter);
    }
};

// actions
const actions = {
    login ({commit},params) {
        //   console.log("action login",params);

        return new Promise((resolve, reject) => {
            HTTP.post('api/public/v1/entrance/logintoken',
                {
                    emailAddress: params.emailAddress,
                    password: params.password
                }
            )
                .then(user => {
                    if (user) {
                        //console.log('store login ok', user.data.user);
                        commit('setLogin', true);
                        commit('setUser', user.data.user);
                        resolve({success : true, msg : 'ok'});
                    } else {
                        resolve({success : false, msg : 'invalid login'});
                        //console.log('login issues');
                    }
                }).catch(err => {
                reject({success : false, msg : err});
                //console.log("login invalid");

            })

        });

        // commit('setUser',{});
    },
    logout ({ commit }) {

       return  HTTP.post('api/public/v1/account/logout',{})
        .then(logoutResult => {
            commit('setLogin',logoutResult,false);

        });
    },
    setRedirectGuardOff ({ commit }) {
        //console.log('setRedirectGuardOff');
        commit('setRedirectGuard',false);
    },
    setRedirectGuardOn ({ commit }) {
        //console.log('setRedirectGuardOn');
        commit('setRedirectGuard',true);
    },
    // determine if my cached JWT is valid
    // todo: Reid catched JWT check
    checkAuthonServer ({ commit }) {
        // shop.getProducts(products => {
        //     commit('setProducts', products)
        // })
        commit('setLogin',false);
    }
};

// mutations
const mutations = {
    setUser (state, user) {
        //console.log('set user mutation',user);
        state.user = user;
        let acls = [];
        // //state.user.acls;
        //let af = _.clone(state.user.acls);
        //console.log('cpy',af,af.length);
        if (state.user.acls) {
            for (let i = 0; i < state.user.acls.length; i++) {
                let acl = state.user.acls[i];
                // console.log("acl--->", i,acl);
                let sos = acl.indexOf('[');
                let eos = acl.indexOf(']');
                let aclStr = acl.substring(0, sos);
                let scopeArr = [];
                let scopeStr = acl.substring(sos + 1, eos);
                // determine if scopeStr is > 1
                if (scopeStr.length < 2) {
                    scopeArr.push('*');
                } else    {
                    scopeArr = scopeStr.split('|');
                }

                let authStr = acl.substring(eos + 2, acl.length);
                //   console.log('sos:', sos, 'eos:', eos, 'aclStr:', aclStr);
                acls.push({"acl": aclStr, "scope": scopeArr, "authorization": authStr});
            }
        }
        state.AclArray = acls;
    },
    setShowBeta (state, flag) {
        state.showBeta = flag;
    },
    setLogin (state, loginFlag) {
        state.isLoggedIn = loginFlag;
    },
    setRedirectGuard (state, flag) {
        state.redirectGuard = flag;
    },


};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default ($axios,useTestData) => ({
    setupAuthToken() {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.post("api/token", {"key": "11941786-C08D-4630-86EC-84B715639B59"})
                .then((response) => {
//                console.log(response.data)
                    $axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token
                    $axios.defaults.timeout = 15000
                })
        }
        else {
            return Promise.resolve();
        }
        // no token necessary in the test setup
    }
})

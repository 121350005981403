import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ApiFactory from './api';

Vue.config.productionTip = false;
// can be yes, no, blank_appl
//let useTestData = 'yes';
let useTestData = 'no';

let Rollbar = require('vue-rollbar');
// set enabled to false while intensively working on code that will probably generate errors!
// set to true for release
// DON'T REMOVE THIS LINE example code_version: '2207051342' 2022, July, 5, 1 : 42 PM
Vue.use(Rollbar, {
  accessToken: '374f08e61cb9462a98128271936d66aa',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  environment: 'production',
  payload: {
    client: {
      javascript: {
        code_version: '2406030954'
      }
    }
  },
  scrubFields: ['ssn','cardVerification']
})

Vue.config.errorHandler = function(err/*,vm,info*/) {
  Vue.rollbar.error(err);
//  console.log(err);
}

Vue.prototype.$api = new ApiFactory(useTestData);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

export default [
    {
        path: '*',
        meta: {
            public: true,
        },
        redirect: {
            path: '/404'
        }
    },
    {
        path: '/404',
        meta: {
            public: true,
        },
        name: 'NotFound',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/routemanagement/NotFound.vue`
            )
    },
    {
        path: '/403',
        meta: {
            public: true
        },
        name: 'AccessDenied',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/routemanagement/Deny.vue`
            )
    },
    {
        path: '/',
        meta: { },
        name: 'Root',
        redirect: {
            name: 'Login'
        }
    },
    {
        path: '/dashboard',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/layouts/DashboardLayout.vue`
            ),
        meta: { breadcrumb: false,
            public: false},
        name: 'dashboard',
        children : [
            {
                path : 'home',
                component : () => import ( `@/views/dashboard/DashboardContainer.vue`),
            }
          ]
    },
    {
        path: '/globalapp',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/layouts/GlobalAppLayout.vue`
            ),
        meta: { breadcrumb: false,  public: false},
        name: 'globalapp',
        children : [
            {
                path : '',
                component : () => import ( `@/views/globalapp/GlobalAppContainer.vue`),
            }
        ]

    },
    {
        path: '/login',
        meta: {
            public: true,
        },
        name: 'Login',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/public/Login.vue`
            )
    },
    {
        path: '/Exit',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/layouts/GlobalAppLayout.vue`
            ),
        meta: {
            public: true,
        },
        name: 'Exit',
        children : [
            {
                path : '',
                component : () => import ( `@/views/globalapp/GlobalAppExit.vue`),
            }
        ]
    },
    {
        path: '/Finish',
        component: () => import(
            /* webpackChunkName: "routes" */
            `@/views/layouts/GlobalAppLayout.vue`
            ),
        meta: {
            public: true,
        },
        name: 'Finish',
        children : [
            {
                path : '',
                component : () => import ( `@/views/globalapp/GlobalAppFinish.vue`),
            }
        ]
    }
];

import Vue from 'vue';
import VueRouter from 'vue-router';
import paths from './paths';
import store from '../store';

Vue.use(VueRouter);
// todo: tdb enable entry url route
//let entryUrl = null;


const router = new VueRouter({
  base: '/',
  mode: 'hash',
  linkActiveClass: 'active',
  routes: paths
});

router.beforeEach((to,from,next) => {
  if ((store.state.globalApplication.applicationID !== "" && from.path === '/globalapp' && to.path !== '/Finish')
      || from.path === '/Finish' || from.path === '/Exit') {
    next(false);
  }
  else {
    if(to.path === '/Finish'){
      //GTM Push page view
      window.dataLayer.push({
        'virtualPageURL': 'globalapp-finish',
        'event': 'pageChange'
      });
    }
    if(to.path === '/Exit'){
      //GTM Push page view
      window.dataLayer.push({
        'virtualPageURL': 'globalapp-exit',
        'event': 'pageChange'
      });
    }
    next();
  }
});

// add router guards
// router guards
// router.beforeEach((to, from, next) => {
//   // console.log('from',from);
//   // do not run on public routes
//   // login must be public or it will be shunned below
//   if (to.meta.public) {
//     return next();
//   }
//
//   if (store.state.session.isLoggedIn) {
//     if (entryUrl) {
//       const url = entryUrl;
//       entryUrl = null;
//       return next(url); // goto stored url
//     } else {
//       return next(); // all is fine
//     }
//   }
//   //await store.dispatch('checkAuth');
//   // we use await as this async request has to finish
//   // before we can be sure
//
//   if (store.state.session.isLoggedIn) {
//     //  NProgress.done();
//     next();
//   } else {
//     console.log("user is not logged in");
//     entryUrl = to.path; // store entry url before redirect
// //    NProgress.done();
//     next('/login');
//   }
// });

// router.afterEach((to, from) => {
//
// });

export default router;

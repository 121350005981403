import axios from 'axios';

export const HTTPAPPLIC = axios.create({
    baseURL: process.env.VUE_APP_SAU_DS_APPL_URL_BASE,
    //withCredentials: true,
    headers: {
// the authorization string expires...
// 'Authorization': 'Bearer Dn3Y',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

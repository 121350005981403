import axios from 'axios';


export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_DS,
    withCredentials: true,
    headers: {
        //   Authorization: 'Bearer {token}'
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

<template>
  <v-app id="inspire">
    <router-view @modalMessage="modalMessage"></router-view>
    <v-overlay :opacity=".5" :value="overlay" :z-index="1337">
        <v-card max-width="400" class="mx-auto" light>
          <v-system-bar :color="overlayColor" height="40" dark>
            <v-card-title class="headline">{{overlayHeader}}</v-card-title>
            <v-spacer min-width="15"></v-spacer>
            <v-icon class="display-1" @click="overlay = false">mdi-close</v-icon>
          </v-system-bar>

          <v-card-subtitle><span v-html="overlayMessage"></span></v-card-subtitle>
        </v-card>
      </v-overlay>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers(
    "globalApplication"
);
//  import Vue from 'vue'
  export default {
    name: 'App',
    data(){
      return {
        overlay: false,
        overlayHeader: "",
        overlayMessage: "",
        overlayColor: ""
      }
    },
    methods: {
      modalMessage: function(value){
        this.overlay = value.show
        this.overlayHeader = value.header
        this.overlayMessage = value.message
        this.overlayColor = value.color
      },
      ...mapActions([
        "loadCampusesAndTracks",
      ])
    },
    components: {},
    props: {
      source: String,
    },
    mounted() {
      // this sets the Authorization header in axios defaults to prepare for secured server calls
      this.$api.authToken.setupAuthToken()
        .then(() => this.loadCampusesAndTracks())
        .catch(() => this.modalMessage({
          show: true,
          header: "Loading Error",
          message: "Sorry, we're having trouble loading available degree programs. Please contact Spring Arbor University.",
          color: "error",
          }));
      //Vue.rollbar.debug('test debug to rollbar');
    }
  }
</script>
<style lang="scss">
    @import 'styles/overrides.scss';
</style>

import _ from "lodash"
import { normalizePhone } from "@/utils";
// when this data changes then also look in the api/globalApplication
const state = {
    applicData: {
        firstName: "", middleName: "", lastName: "", email: "", preferredName: "",
        nameSuffix: "", maidenName: "", phone: "", cell: "", currentAddress: "",
        line2: "", city: "", state: "", zip: "", country: "",
        gender: "", birthdate: "", workPhone: "", workPhoneExt: "", preferredContactMethod: "",
        birthCountry: "", citizenship: "", maritalStatus: "", veteran: "", hispanic: "",
        raceAmerican: "", raceAsian: "", raceBlack: "", racePacific: "", raceWhite: "",
        specialNeedsDescr: "", crime: "", whyCrime: "", track: "", entryTerm: "", campus: "",
        whenEnroll: "", graduateFromSAU: "", transfer: "", fafsa: "", learnAbout: "",
        prevAttendedSAU: "", prevAttendedDate: "", transcriptRelease: "", rank: "", churchName: "",
        churchDenom: "", churchCity: "", churchState: "", highSchoolNumber: 0, hsName: "",
        hsCity: "", hsState: "", hsCountry: "", hsGradYear: 0, hsGradType: "",
        homeSchoolGrad: "", desiredDegreeLevel: "", highSchoolDiplomaType: "", highSchoolDiplomaName: "", GEDstate: "",
        GEDdate: "", GEDname: "", ssNo: "", veteranBranch: "", veteranStat: "",
        collAttCode1: "", collAttName1: "", collAttLastYear1: "", collAttBalance1: "", collAttCode2: "",
        collAttName2: "", collAttLastYear2: "", collAttBalance2: "", collAttCode3: "", collAttName3: "",
        collAttLastYear3: "", collAttBalance3: "", collAttCode4: "", collAttName4: "", collAttLastYear4: "",
        collAttBalance4: "", promoCode: "", payMethod: "", agreeValues: "", conc: "",
        teachCertType: "", teachCertCrnt: "", teachCertName: "", teachCertExpDate: ""
    },
    applicationID: "",
    emailKey: "",
    campusTracks: [],
    trackRequirements: {
        teachingCert: false,
        postBaccTeaching: false,
        nursing: false,
        applicationPayment: false,
        recommendationMinimum: 0
    },
    highSchoolSearchResult: [],
    currentStep: -1,
    collegeSearchResult: [],
    countries: [],
    stateChoices: [
        { txt: 'Arizona', st: 'AZ' },
        { txt: 'Alabama', st: 'AL' },
        { txt: 'Alaska', st: 'AK' },
        { txt: 'Arizona', st: 'AZ' },
        { txt: 'Arkansas', st: 'AR' },
        { txt: 'California', st: 'CA' },
        { txt: 'Colorado', st: 'CO' },
        { txt: 'Connecticut', st: 'CT' },
        { txt: 'Delaware', st: 'DE' },
        { txt: 'District of Columbia', st: 'DC' },
        { txt: 'Florida', st: 'FL' },
        { txt: 'Georgia', st: 'GA' },
        { txt: 'Hawaii', st: 'HI' },
        { txt: 'Idaho', st: 'ID' },
        { txt: 'Illinois', st: 'IL' },
        { txt: 'Indiana', st: 'IN' },
        { txt: 'Iowa', st: 'IA' },
        { txt: 'Kansas', st: 'KS' },
        { txt: 'Kentucky', st: 'KY' },
        { txt: 'Louisiana', st: 'LA' },
        { txt: 'Maine', st: 'ME' },
        { txt: 'Maryland', st: 'MD' },
        { txt: 'Massachusetts', st: 'MA' },
        { txt: 'Michigan', st: 'MI' },
        { txt: 'Minnesota', st: 'MN' },
        { txt: 'Mississippi', st: 'MS' },
        { txt: 'Missouri', st: 'MO' },
        { txt: 'Montana', st: 'MT' },
        { txt: 'Nebraska', st: 'NE' },
        { txt: 'Nevada', st: 'NV' },
        { txt: 'New Hampshire', st: 'NH' },
        { txt: 'New Jersey', st: 'NJ' },
        { txt: 'New Mexico', st: 'NM' },
        { txt: 'New York', st: 'NY' },
        { txt: 'North Carolina', st: 'NC' },
        { txt: 'North Dakota', st: 'ND' },
        { txt: 'Ohio', st: 'OH' },
        { txt: 'Oklahoma', st: 'OK' },
        { txt: 'Oregon', st: 'OR' },
        { txt: 'Pennsylvania', st: 'PA' },
        { txt: 'Rhode Island', st: 'RI' },
        { txt: 'South Carolina', st: 'SC' },
        { txt: 'South Dakota', st: 'SD' },
        { txt: 'Tennessee', st: 'TN' },
        { txt: 'Texas', st: 'TX' },
        { txt: 'Utah', st: 'UT' },
        { txt: 'Vermont', st: 'VT' },
        { txt: 'Virginia', st: 'VA' },
        { txt: 'Washington', st: 'WA' },
        { txt: 'West Virginia', st: 'WV' },
        { txt: 'Wisconsin', st: 'WI' },
        { txt: 'Wyoming', st: 'WY' }
    ],
    degLvlQueryArgument: ""
};

const getters = {

};

const actions = {
    async load({commit}) {
        let returnVal = await this._vm.$api.globalApplication.load(state.applicationID,state.emailKey);
        let dataSet = {};
        if (returnVal.data) {
            dataSet = returnVal.data;
        }
        else {
            dataSet = returnVal;
        }
        //dataSet.birthdate = convert_MMDDYYYY(dataSet.birthdate);
        commit('setApplicationData',dataSet);
        if (dataSet.currentStep) {  // current step can't be 0
            commit('setCurrentStep', dataSet.currentStep);
        }
    },
    async saveToServer() {
        let dataParam = { ...state.applicData };
        dataParam.em = state.emailKey;
        dataParam.currentStep = state.currentStep;
        if (dataParam.phone) {
            dataParam.phone = normalizePhone(dataParam.phone);
        }
        let returnVal = await this._vm.$api.globalApplication.saveToServer(state.applicationID,dataParam);
        if (!returnVal) {
            return {};
        }
        return (returnVal.data ? returnVal.data : returnVal);
    },
    // eslint-disable-next-line no-empty-pattern
    async saveNewPassword({},params) {
        let dataParam = { em: state.emailKey, newPassword: params.newPassword };
        let returnVal = await this._vm.$api.globalApplication.saveNewPassword(state.applicationID,dataParam);
        if (!returnVal) {
            return {};
        }
        return (returnVal.data ? returnVal.data : returnVal);
    },
    // eslint-disable-next-line no-empty-pattern
    async payFee({},payData) {
        let dataParam = { ...payData };
        dataParam.em = state.emailKey;
        let returnVal = await this._vm.$api.globalApplication.payFee(state.applicationID,dataParam);
        if (!returnVal) {
            return {};
        }
        return (returnVal.data ? returnVal.data : returnVal);
    },
    async loginWithPassword({ commit }, params) {
        let returnVal = await this._vm.$api.globalApplication.loginToApplication(params.emailAddress,params.password,params.oneTimePassword);
        if (returnVal && returnVal.data && returnVal.data.length > 0) {
            commit('setApplicationIdAndEmail', {appId: returnVal.data[0].app_id, emailKey: params.emailAddress});
        }
        return returnVal.data;
    },
    async loginWithOTP({ commit }, params) {
        let returnVal = await this._vm.$api.globalApplication.loginToApplication(params.emailAddress,params.password,params.oneTimePassword);
        if (returnVal && returnVal.data && returnVal.data.length > 0) {
            commit('setApplicationIdAndEmail', {appId: returnVal.data[0].app_id, emailKey: returnVal.data[0].email});
        }
        return returnVal.data;
    },
    // eslint-disable-next-line no-empty-pattern
    async requestNewOTP({}, params) {
        let returnVal = await this._vm.$api.globalApplication.requestNewOTP(params.emailAddress,params.password);
        return returnVal.data;
    },
    // eslint-disable-next-line no-empty-pattern
    async forgotPassword({}, params) {
        await this._vm.$api.globalApplication.forgotPassword(params.emailAddress,params.cell);
    },
    async startApplication({ commit },params) {
        let returnVal = await this._vm.$api.globalApplication.startApplication(params);
        if (returnVal && returnVal.data) {
            commit('setApplicationIdAndEmail', { appId: returnVal.data.app_id, emailKey: params.email } );
            commit('setCurrentStep',1);
        }
        return returnVal.data;
    },
    // eslint-disable-next-line no-empty-pattern
    async finishApplication({},params) {
        let returnVal = await this._vm.$api.globalApplication.finishApplication(state.applicationID,state.emailKey,params.promoCode,params.payMethod,params.agreeValues,params.ssNo);
        return returnVal;
    },
    async loadCampusesAndTracks({commit}) {
        let returnVal = await this._vm.$api.globalApplication.loadCampusesAndTracks();
        commit('setCampusesTracks',returnVal.data);
        return returnVal.data;
    },
    async loadTrackInfo({commit},params) {
        if (params.track) {
            let returnVal = await this._vm.$api.globalApplication.loadTrackInfo(params.track);
            commit('setTrackRequirements', returnVal.data);
            return returnVal.data;
        }
        else {
            let resetVal =
                {
                    teachingCert: false,
                    postBaccTeaching: false,
                    nursing: false,
                    applicationPayment: false,
                    recommendationMinimum: 0
                };
            commit('setTrackRequirements',resetVal);
            return resetVal;
        }
    },
    async searchHighSchools({commit},params) {
        let returnVal = await this._vm.$api.globalApplication.searchHighSchools(params.searchString);
        commit('setHighSchoolSearchResult',returnVal.data);
        return returnVal.data;
    },
    async searchColleges({commit},params) {
        let returnVal = await this._vm.$api.globalApplication.searchColleges(params.searchString);
        commit('setCollegeSearchResult',returnVal.data);
        return returnVal.data;
    },
    async loadCountries({commit}) {
        let returnVal = await this._vm.$api.globalApplication.loadCountries();
        commit('setCountries',returnVal.data);
        return returnVal.data;
    },
    // eslint-disable-next-line no-empty-pattern
    async lookupIP({},params) {
        let returnVal = await this._vm.$api.globalApplication.lookupIP(params.ip);
        return (returnVal.data ? returnVal.data : returnVal);
    }
};

const mutations = {
    setApplicationData(state, dat) {
        state.applicData = _.omit(dat,['currentStep']);
    },
    updateApplicationDataProps(state, dat) {
        _.assign(state.applicData,dat);
    },
    setApplicationIdAndEmail(state, dat) {
        state.applicationID = dat.appId;
        state.emailKey = dat.emailKey;
    },
    setCampusesTracks(state,dat) {
        state.campusTracks = dat;
    },
    setTrackRequirements(state,dat) {
        state.trackRequirements = dat;
    },
    setHighSchoolSearchResult(state,dat) {
        state.highSchoolSearchResult = dat;
    },
    setCurrentStep(state,stepVal) {
        state.currentStep = stepVal;
    },
    setCollegeSearchResult(state,dat) {
        state.collegeSearchResult = dat;
    },
    setCountries(state,dat) {
        state.countries = dat;
    },
    setDegLvlQueryArgument(state,dat) {
        state.degLvlQueryArgument = dat;
    }
};

/*function convert_MMDDYYYY(dt) {
    if (dt === null || dt === "") {
        return null
    }
    let yr = dt.substring(6);
    let monNum = parseFloat(dt.substring(0,2));
    monNum = monNum - 1;
    let day = dt.substring(3,5);
    return new Date(yr, monNum, day, 0,0)
}*/

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

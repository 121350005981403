import _ from "lodash"

function formatDate(date) {
    if (!date || isNaN(date.getTime())) return '';
//console.log('in format date');
//console.log(`date is ${date}`)
    const year = date.getFullYear() + '';
//    console.log(`year + ${year}`)
    const month = (date.getMonth() + 1) + '';
//    console.log(`month + ${month}`)
    const day = date.getDate() + '';
//    console.log(`day + ${day}`)
    return `${month.padStart(2,'0')}/${day.padStart(2,'0')}/${year}`;
}

function parseDate(date) {
    if (!date) {
        return null;
    }
    date = date.replaceAll('-','/')
    let [month,day,year] = date.split('/');
    if (!month || !day || !year) {
        return null;
    }
    if (year.length === 2 && year !== '20' && year !== '19') {
        let yearTwoDigit = new Date().getFullYear() - 2000
        if (parseInt(year) > yearTwoDigit) {
            year = '19' + year
        }
        else {
            year = '20' + year
        }
    }
    let dateStr = `${year}-${month.padStart(2,'0')}-${day.padStart(2,'0')}T00:00:00`
//    console.log(`parsed date str ${dateStr}`)
    return new Date(dateStr);
}

function gradYearRange() {
    return _.range((new Date().getFullYear())+1,1950,-1);
}

function cardYearRange() {
    var list = [],
    lowEnd = parseInt(new Date().getFullYear().toString().substr(-2)),
    highEnd = lowEnd + 15
    for (var i = lowEnd; i <= highEnd; i++) {
        list.push(i);
    }
    return list;
}

function normalizePhone(inPhone) {
    let onlyDigits = inPhone.replace(/\D/gi, '');
    if (onlyDigits.length >= 10) {
        return onlyDigits.substr(0,3) + "-" + onlyDigits.substr(3,3) + "-" + onlyDigits.substr(6,4);
    }
    else {
        return onlyDigits;
    }
}

export { formatDate, parseDate, gradYearRange, cardYearRange, normalizePhone }

import Vue from 'vue';
import Vuex from 'vuex';
import session from './modules/session';
import globalApplication from "./modules/globalApplication";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    session,
    globalApplication
  },
  strict: debug
  // plugins: debug ? [createLogger()] : []
})

export default ($axios,useTestData) => ({
    load(applicationID,currEmail) {
        if (useTestData === 'no') {
            return $axios.get("api/application/" + applicationID, { params: { em: currEmail}});
        }
        else if (useTestData === 'yes') {
            // no token necessary in the test setup
            //console.log('testing auth token');
            return Promise.resolve({
                firstName: "Test", lastName: "Test", middleName: "Test", email: "krm1223@gmail.com", preferredName: "Kim",
                nameSuffix: "III", maidenName: "Kiracofe", phone: "5676444542", cell: "5177506633", currentAddress: "106 E Main St",
                line2: "Dietzman Bldg", city: "Spring Arbor", state: "MI", zip: "49283", country: "USA",
                gender: "F", birthdate: "01/01/1990", workPhone: "5177506633", workPhoneExt: "1633", preferredContactMethod: "Email",
                birthCountry: "USA", citizenship: "USA", maritalStatus: "M", veteran: "No", hispanic: "N",
                raceAmerican: "N", raceAsian: "Y", raceBlack: "Y", racePacific: "N", raceWhite: "N",
                specialNeedsDescr: "N/A", crime: "Y", whyCrime: "parking infraction", track: "BSW", campus: "Spring Arbor Campus", highSchoolDiplomaType: "highschool",
                whenEnroll: "Item 1", graduateFromSAU: "Y", transfer: "Y", fafsa: "1", learnAbout: "Google ad",
                prevAttendedSAU: "Y", prevAttendedDate: "03/14/2009", transcriptRelease: "Y", rank: "2", churchName: "Immanuel Lutheran",
                churchDenom: "LUTH", churchCity: "Saginaw", churchState: "MI", highSchoolNumber: 232132, hsName: "Jackson High School",
                hsCity: "Jackson", hsState: "MI", hsCountry: "USA", hsGradYear: 1992, hsGradType: "HSD",
                homeSchoolGrad: "", desiredDegreeLevel: "Bachelors", highSchoolDiplomaName: "Test Maiden", GEDstate: "WA",
                GEDdate: 2008, GEDname: "Test GED", ssNo: "000-00-0000", veteranBranch: "Army", veteranStat: "Reserve",
                collAttCode1: 70291, collAttName1: "Platt College", collAttLastYear1: 1993, collAttBalance1: "N", collAttCode2: 70288,
                collAttName2: "Northwestern Tech Institute", collAttLastYear2: 1994, collAttBalance2: "N", collAttCode3: 70284, collAttName3: "Adams State University",
                collAttLastYear3: 1995, collAttBalance3: "Y", collAttCode4: 20006, collAttName4: "Simon Fraser University", collAttLastYear4: 1996,
                collAttBalance4: "Y", promoCode: "", payMethod: "",
                currentStep: 1
            });
        }
        else {  // useTestData === blank_appl
            return Promise.resolve({
                firstName: "", lastName: "", middleName: "", email: "", preferredName: "",
                nameSuffix: "", maidenName: "", phone: "", cell: "", currentAddress: "",
                line2: "", city: "", state: "", zip: "", country: "",
                gender: "", birthdate: "", workPhone: "", workPhoneExt: "", preferredContactMethod: "",
                birthCountry: "", citizenship: "", maritalStatus: "", veteran: "", hispanic: "",
                raceAmerican: "", raceAsian: "", raceBlack: "", racePacific: "", raceWhite: "",
                specialNeedsDescr: "", crime: "", whyCrime: "", trak: "", campus: "", highSchoolDiplomaType: "",
                whenEnroll: "", graduateFromSAU: "", transfer: "", fafsa: "", learnAbout: "",
                prevAttendedSAU: "", prevAttendedDate: "", transcriptRelease: "", rank: "", churchName: "",
                churchDenom: "", churchCity: "", churchState: "", highSchoolNumber: 0, hsName: "",
                hsCity: "", hsState: "", hsCountry: "", hsGradYear: 0, hsGradType: "",
                homeSchoolGrad: "", desiredDegreeLevel: "", highSchoolDiplomaName: "", GEDstate: "",
                GEDdate: 0, GEDname: "", ssNo: "", veteranBranch: "", veteranStat: "",
                collAttCode1: 0, collAttName1: "", collAttLastYear1: 0, collAttBalance1: "N", collAttCode2: 0,
                collAttName2: "", collAttLastYear2: 0, collAttBalance2: "", collAttCode3: 0, collAttName3: "",
                collAttLastYear3: 0, collAttBalance3: "", collAttCode4: 0, collAttName4: "", collAttLastYear4: 0,
                collAttBalance4: "", promoCode: "", payMethod: "",
                currentStep: 0
            });

        }
    },
    saveToServer(applicationID,applicationData) {
        if (useTestData === 'no') {
            return $axios.patch("api/application/" + applicationID,applicationData);
        }
        else {  // useTestData of yes or blank_appl
//            console.log("would've saved :");
//            console.log(applicationData);
            return Promise.resolve();
        }
    },
    saveNewPassword(applicationID,applicationData) {
        if (useTestData === 'no') {
            return $axios.post("api/application/NewPass/" + applicationID,applicationData);
        }
        else {  // useTestData of yes or blank_appl
//            console.log("would've saved a new password");
//            console.log(applicationData);
            return Promise.resolve();
        }
    },
    payFee(applicationID,payData) {
        if (useTestData === 'no') {
            return $axios.post("api/application/PayFee/" + applicationID,payData);
        }
        else {  // useTestData of yes or blank_appl
//            console.log("would've paid fee");
//            console.log(payData);
            return Promise.resolve();
        }
    },
    loginToApplication(emailAddress,password,onetimepass) {
        if (useTestData === 'no') {
            let parmObject = { };
            if (onetimepass) {
                parmObject.otp = onetimepass;
            }
            else {
                parmObject.email = emailAddress;
                parmObject.pass = password;
            }
            return $axios.get("api/application", { params: parmObject});
        }
        else {  // useTestData of yes or blank_appl
//            console.log("faked application login");
            return Promise.resolve({
                data: [ {
                    app_id: "228793",
                    isDone: "No"
                } ]
            });
        }
    },
    forgotPassword(emailAddress,cellNumber) {
        if (useTestData === 'no') {
            return $axios.post("api/application/ForgotPass",
                {em: emailAddress, cell: cellNumber });
        }
        else { // useTestData of yes or blank_appl
//            console.log("skipping forgotten password for :",emailAddress,cellNumber);
            return Promise.resolve();
        }
    },
    requestNewOTP(emailAddress,password) {
        if (useTestData === 'no') {
            return $axios.get("api/application/newotp", { params: { email: emailAddress, pass: password }});
        }
        else { // useTestData of yes or blank_appl
            return Promise.resolve({
                data: [{
                    otp: "QQQQQQQQQQQQQQQQ",
                    isDone: "No"
                }]
            });
        }
    },
    startApplication(startingInfo) {
        if (useTestData === 'no') {
            return $axios.post("api/application",startingInfo);
        }
        else {  // useTestData of yes or blank_appl
//            console.log("didn't start a real application using data: ",startingInfo);
            return Promise.resolve({
                data : {
                    app_id: 228793
                }
            });
        }
    },
    finishApplication(applicationID,emailAddress,promoCode,payMethod,agreeValues,ssNo) {
        if (useTestData === 'no') {
            return $axios.post("api/application/SubmitAppl/" + applicationID,
                { em: emailAddress, promoCode: promoCode, payMethod: payMethod, agreeValues: agreeValues, ssNo: ssNo });
        }
        else { // useTestData of yes or blank_appl
            //console.log("skipping application submission for :",emailAddress);
            return Promise.resolve();
        }
    },
    loadCampusesAndTracks() {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.get("api/CampusesTracks");
        }
        else { // useTestData of yes
            return Promise.resolve({
               data: [
                   { prog: 'GRAD',
                       traks: [
                           {
                               trak: 'MACA',
                               txt: 'Master of Arts in Counseling',
                               campuses: [
                                   { campus: 'Flint '}
                               ]
                           },
                           {
                               trak: 'MML',
                               txt: 'Master of Arts in Management & Organizational Leadership',
                               campuses: [
                                   { campus: 'Flint' },
                                   { campus: 'Online' },
                                   { campus: 'Virtual Classroom' }
                               ]
                           },
                           {
                               trak: 'MBA',
                               txt: 'Master of Business Administration',
                               campuses: [
                                   { campus: 'Virtual Classroom' }
                               ]
                           },
                           {
                               trak: 'MSW',
                               txt: 'Master of Social Work',
                               campuses: [
                                { campus: 'Online' }
                               ]
                           }
                       ]
                   },
                   { prog: 'UNDG',
                       traks: [
                           {
                               trak: 'BAGS',
                               txt: 'Bachelor of Arts in General Studies',
                               campuses: [
                                   { campus: 'Flint' }
                               ]
                           },
                           {
                               trak: 'BSW',
                               txt: 'Bachelor of Social Work',
                               campuses: [
                                   { campus: 'Spring Arbor Campus' }
                               ]
                           }
                       ]
                   }
               ]
            });
        }
    },
    loadTrackInfo(track) {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.get('api/TrackInfo/' + track);
        }
        else {  // useTestData of yes
//            console.log('faking the call to load track info')
            return Promise.resolve({
                data: {"teachingCert":true,"postBaccTeaching":true,"nursing":true,"applicationPayment":true,"recommendationMinimum":3}
            })
        }
    },
    searchHighSchools(searchString) {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.get("api/highschools",
                { params: { searchValue: searchString }});
        }
        else { // useTestData of yes
            return Promise.resolve({
               data:
                   [
                   {"value":"20311^Polk Inlet School^Ketchikan^AK^USA","text":"Polk Inlet School  -  Ketchikan, AK"},
                   {"value":"101906^Polk Comm Coll Collegiate Hs^Winter Haven^FL^","text":"Polk Comm Coll Collegiate Hs  -  Winter Haven, FL"},
                   {"value":"101642^Sabal Palm School^Polk City^FL^","text":"Sabal Palm School  -  Polk City, FL"},
                   {"value":"160080^North Polk Jr-Sr High School^Alleman^IA^USA","text":"North Polk Jr-Sr High School  -  Alleman, IA"},
                   {"value":"163850^Southeast Polk High School^Runnells^IA^USA","text":"Southeast Polk High School  -  Runnells, IA"},
                   {"value":"252045^Polkville Christian Academy^Morton^MS^","text":"Polkville Christian Academy  -  Morton, MS"},
                   {"value":"343131^Anson County Early School^Polkton^NC^","text":"Anson County Early School  -  Polkton, NC"},
                   {"value":"343179^Polk County High School^Columbus^NC^USA","text":"Polk County High School  -  Columbus, NC"},
                   {"value":"343232^Polk Youth Center^Raliegh^NC^USA","text":"Polk Youth Center  -  Raliegh, NC"},
                   {"value":"281945^High Plains Community Hs^Polk^NE^USA","text":"High Plains Community Hs  -  Polk, NE"},
                   {"value":"430115^Polk County High School^Benton^TN^USA","text":"Polk County High School  -  Benton, TN"}
                   ]
            });
        }
    },
    searchColleges(searchString) {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.get("api/colleges", { params: { searchValue: searchString }});
        }
        else {  // useTestData of yes
            return Promise.resolve({
                data:
                    [
                    {"val":"757^Polk Community College^FL^Winter Haven","txt":"Polk Community College-Winter Haven, FL"},
                    {"val":"3061^Anson Community College^NC^Polkton","txt":"Anson Community College-Polkton, NC"}
                    ]
            });
        }
    },
    loadCountries() {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.get("api/countries");
        }
        else {  //useTestData of yes
            return Promise.resolve({
                    data: [
                        {"ctry":"USA","txt": "UNITED STATES"},
                        {"ctry":"CDN","txt":"CANADA"},
                        {"ctry":"RCH","txt":"CHILE"}
                    ]
                }
            );
        }
    },
    lookupIP(ip) {
        if (useTestData === 'no' || useTestData === 'blank_appl') {
            return $axios.get("api/IPInfo/" + ip);
        }
        else {  //useTestData of yes
            return Promise.resolve({});
        }
    }
})
